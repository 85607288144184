export const generateInitialValues = ({ fields }) => {
  let initialValues = {}

  for (let field of fields) {
    switch (field.type) {
      case "checkbox":
      case "multiselect":
        initialValues[field.name] = []
        break

      case "number":
        initialValues[field.name] = ""
        break

      case "schedule":
        initialValues[field.name] = {}
        for (let fieldName of field.fieldNames) {
          initialValues[field.name][fieldName] = ""
        }
        break

      case "select":
        initialValues[field.name] = { value: "", label: "" }
        break

      case "date":
        initialValues[field.name] = {
          month: {
            label: "",
            value: "",
          },
          date: {
            label: "",
            value: "",
          },
          year: "",
        }
        break

      case "address":
        let addressInitialValues = {}
        if (field.initialValues) {
          addressInitialValues = JSON.parse(field.initialValues)
        }
        initialValues[field.name] = {
          barangay: addressInitialValues.barangay || null,
          city: addressInitialValues.city || null,
          province: addressInitialValues.province || null,
          streetAddress: addressInitialValues.streetAddress || "",
          addressType: addressInitialValues.addressType || "",
        }
        break

      default:
        initialValues[field.name] = ""
        break
    }
  }

  return initialValues
}
